export const environment = {
  production: true,
  signature_key: 'PzyLzFCOLLtbw-gxAAFUAjh-3SDgznZm',
  baseUrl: 'https://www.cubatel.com/server/',
  client_id: 'f0ed0633-f8ed-df96-7211-35cc830e955e',
  gcId: '214805215376-gubou9ilf14uc811lcdslautu9hjejak.apps.googleusercontent.com',
  redirectPayPalUrl: 'https://www.cubatel.com/server/redirect-dev.html?payPalUrl=',
  rewardsUrl: 'https://www.googleapis.com/androidpublisher/v3/applications/com.cubatel.android/reviews',
  authorizeAccountGoogle: 'https://accounts.google.com/o/oauth2/token',
  locale: 'es',
  lazyLoadLocation: 'https://cdn.cubatel.com/assets-v3/',
  fpApiToken: 'jxNr6s4iwzOt99QKkPcC',
  sendFingerprint: true,
  host: 'https://www.cubatel.com',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.cubatel.android',
  appleStoreUrl: 'https://apps.apple.com/us/app/cubatel/id1300396049',
  expirationCode: 259200000,
  expAmazonWizard: 604800000
};
