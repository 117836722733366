import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RedirectGuard } from '@app/helpers/redirect.guard';
import { RedirectWithParamsGuard } from './guards/redirect-with-params.guard';
import { RemittanceSeoModule } from './remittance-seo/remittance-seo.module';
import { RemittanceSeoComponent } from './remittance-seo/remittance-seo.component';
import { LayoutComponent } from './marketplace/layout/layout/layout.component';
import {FirebaseProductGuard} from '@app/guards/firebase-product.guard';
import {FirebaseProductsGuard} from '@app/guards/firebase-products.guard';

const routes: Routes = [
  /*{
    path: 'productos-compras-cuba-online',
    pathMatch: 'full' ,
    loadChildren: () =>
      import('./marketplace/layout/layout.module').then((m) => m.LayoutModule),
  },*/
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'salir', component: HomeComponent, pathMatch: 'full', data: { name: 'salir' } },
  { path: 'oferta', component: HomeComponent, pathMatch: 'full' },
  { path: 'sms', loadChildren: () => import('./sms/sms.module').then(m => m.SmsModule) },
  { path: 'recargas', loadChildren: () => import('./recharge/recharge.module').then(m => m.RechargeModule) },
  { path: 'market', loadChildren: () => import('./marketplace/layout/layout.module').then(m => m.LayoutModule) },
  { path: 'envio-dinero-cuba', loadChildren: () => import('./remittance-seo/remittance-seo.module').then(m => m.RemittanceSeoModule) },
  { path: 'llamadas-a-cuba', loadChildren: () => import('./calls-seo/calls-seo.module').then(m => m.CallsSeoModule) },
  { path: 'aplicacion', loadChildren: () => import('./apps-seo/apps-seo.module').then(m => m.AppsSeoModule) },
  { path: 'mayorista', loadChildren: () => import('./retail-seo/retail-seo.module').then(m => m.RetailSeoModule) },
  { path: 'sms-a-cuba', loadChildren: () => import('./sms-seo/sms-seo.module').then(m => m.SmsSeoModule) },
  { path: 'recargas-nauta', loadChildren: () => import('./nauta-seo/nauta-seo.module').then(m => m.NautaSeoModule) },
  { path: 'nauta', loadChildren: () => import('./nauta/nauta.module').then(m => m.NautaModule) },
  { path: 'perfil', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: 'verificacion-pago', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'asistencia', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
  { path: 'recomendar-amigo', loadChildren: () => import('./recommendation/recommendation.module').then(m => m.RecommendationModule) },
  { path: 'cambiar-contraseña', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'confirmar-correo', loadChildren: () => import('./email-confirmation/email-confirmation.module').then(m => m.EmailConfirmationModule) },
  { path: 'terminos', loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
  { path: 'terminos-remesas', loadChildren: () => import('./remittance-terms/remittance-terms.module').then(m => m.RemittanceTermsModule) },
  { path: 'privacidad', loadChildren: () => import('./remittance-privacy/remittance-privacy.module').then(m => m.RemittancePrivacyModule) },
  { path: 'quienes-somos', loadChildren: () => import('./about-as/about-as.module').then(m => m.AboutAsModule) },
  { path: 'quienes-somos', loadChildren: () => import('./about-as/about-as.module').then(m => m.AboutAsModule) },
  { path: 'contactanos', loadChildren: () => import('./contact-as/contact-as.module').then(m => m.ContactAsModule) },
  { path: 'store', loadChildren: () => import('./recharge-verify/recharge-verify.module').then(m => m.RechargeVerifyModule) },
  { path: 'recharge', loadChildren: () => import('./recharge-verify/recharge-verify.module').then(m => m.RechargeVerifyModule) },
  { path: 'message/checkout', loadChildren: () => import('./recharge-verify/recharge-verify.module').then(m => m.RechargeVerifyModule) },
  { path: 'mantenimiento', loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenaceModule) },

  /* Routes from push notification*/
  { path: 'stu/cubacel/history', redirectTo: '/perfil/recargas', pathMatch: 'full' }, //TODO: Add filter by operator
  { path: 'stu/nauta/history', redirectTo: '/perfil/recargas', pathMatch: 'full' }, //TODO: Add filter by operator
  { path: 'stu/messages/history', redirectTo: '/perfil/mensajes', pathMatch: 'full' },
  { path: 'stu/purchase-history/sms', redirectTo: '/perfil/mensajes', pathMatch: 'full' },
  { path: 'stu/purchase-history/calls', redirectTo: '/llamadas-a-cuba', pathMatch: 'full' },
  { path: 'stu/cubacel/recharge', canActivate: [RedirectWithParamsGuard], component: HomeComponent, pathMatch: 'full', data: { redirectTo: 'recargas' } },
  { path: 'stu/nauta/recharge', redirectTo: '/nauta', pathMatch: 'full' },
  { path: 'stu/messages/recharge', redirectTo: '/verificacion-pago/sms', pathMatch: 'full' },
  { path: 'stu/cubacel/recharge/cashback', redirectTo: '/recargas/reembolso', pathMatch: 'full' }, //TODO
  { path: 'stu/cubacel/contacts', redirectTo: '/perfil/contactos', pathMatch: 'full' }, //TODO: Add filter by operator
  { path: 'stu/nauta/contacts', redirectTo: '/perfil/contactos', pathMatch: 'full' }, //TODO: Add filter by operator
  { path: 'stu/messages/contacts', redirectTo: '/perfil/contactos', pathMatch: 'full' }, //TODO: Add filter by operator
  { path: 'stu/messages/new', redirectTo: '/sms', pathMatch: 'full' },
  { path: 'stu/rewards/cashback', redirectTo: '/perfil/recompensas/reembolso', pathMatch: 'full' },
  { path: 'stu/rewards/discounts', redirectTo: '/perfil/recompensas/descuentos', pathMatch: 'full' },
  { path: 'stu/profile', redirectTo: '/perfil', pathMatch: 'full' },
  { path: 'stu/payment-methods', redirectTo: '/perfil/tarjetas', pathMatch: 'full' },
  { path: 'stu/contact-us', redirectTo: '/contactanos', pathMatch: 'full' },
  { path: 'stu/faq', redirectTo: '/asistencia', pathMatch: 'full' },
  { path: 'stu/legal', redirectTo: '/terminos', pathMatch: 'full' },
  { path: 'stu/logout', redirectTo: '/salir', pathMatch: 'full' },
  { path: 'stu/calls/dial', redirectTo: '/llamadas-a-cuba', pathMatch: 'full' },
  { path: 'stu/calls/contacts', redirectTo: '/llamadas-a-cuba', pathMatch: 'full' },
  { path: 'stu/calls/history', redirectTo: '/llamadas-a-cuba', pathMatch: 'full' },
  { path: 'stu/calls/recharge', redirectTo: '/llamadas-a-cuba', pathMatch: 'full' },
  { path: 'stu/cart/clear', redirectTo: '/', pathMatch: 'full' },
  { path: 'stu/cart/open', redirectTo: '/', pathMatch: 'full' },
  { path: 'stu/rates', redirectTo: '/', pathMatch: 'full' },
  { path: 'stu/referral', redirectTo: '/', pathMatch: 'full' },


  /* Store */
  { path: 'stu/store', redirectTo: '/market/tiendas-asociadas', pathMatch: 'full' },
  { path: 'stu/store/suppliers', redirectTo: '/market/tiendas-asociadas', pathMatch: 'full' },
  { path: 'stu/store/supplier', redirectTo: '/market/tiendas-asociadas', pathMatch: 'full' },
  { path: 'stu/store/category', redirectTo: '/market/tienda-online-cuba', pathMatch: 'full' },
  { path: 'stu/store/products', canActivate: [FirebaseProductsGuard], pathMatch: 'full' },
  { path: 'stu/store/product', canActivate: [FirebaseProductGuard], pathMatch: 'full' },
  { path: 'stu/store/reviews', redirectTo: '/market', pathMatch: 'full' },
  { path: 'stu/store/reviews/add', redirectTo: '/market', pathMatch: 'full' },
  { path: 'stu/store/reviews/details', redirectTo: '/market', pathMatch: 'full' },
  { path: 'stu/reviews', redirectTo: '/market', pathMatch: 'full' },

  /* Remittance */
  { path: 'stu/remittance', canActivate: [RedirectWithParamsGuard], component: RemittanceSeoComponent, pathMatch: 'full', data: { redirectTo: 'envio-dinero-cuba' } },

  /*Not Found Page*/
  { path: '**', component: NotFoundComponent },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'legacy',
 paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
